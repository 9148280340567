import {FormattedMessage} from "react-intl";
import {IconUserPlus, IconUsers} from "@tabler/icons";

export const users = {
  id: 'users',
  title: <FormattedMessage id="users" />,
  type: 'group',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      url: '/users/all',
      icon: IconUsers,
      breadcrumbs: false
    },
    {
      id: 'add_user',
      title: <FormattedMessage id="add_user" />,
      type: 'item',
      url: '/dashboard/analytics',
      icon: IconUserPlus,
      breadcrumbs: false
    }
  ]
};