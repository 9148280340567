import React from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';

import logo from './../assets/images/logo_company.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo_company.png';
 *
 */

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    return (
      <img src={logo} alt="ADMIN PANEL" width="150" />
    );
};

export default Logo;
