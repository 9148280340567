import {gql} from "@apollo/client";

export const GET_ORDERS = gql`
    query Orders($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        Orders(
            offset: $offset
            limit: $limit
            filters: $filters
            sort: $sort
            populate: $populate
        ) {
            docs {
                _id
                company {
                    _id
                    name
                }
                orderId
                cookies
                repeatOrder
                total
                products {
                    _id
                    price
                    total
                    pcs
                    url
                    productIdAPI
                    product {
                        _id
                        productId
                        productIdAPI
                        productMeta {
                            uk {
                                name
                            }
                        }
                        categories
                    }
                    provider {
                        _id
                        providerId
                        name
                    }
                    chargeUsd
                    apiOrderId
                    status
                }
                status
                charge
                chargeUsd
                createdAt
                updatedAt
                invoice
            }
            total
        }
    }
`;

export const GET_CATEGORIES = gql`
    query Categories($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        Categories(offset: $offset, limit: $limit, filters: $filters, sort: $sort, populate: $populate) {
            docs {
                _id
                company {
                    _id
                    name
                }
                categoryId
                parent {
                    _id
                    categoryId
                    categoryMeta {
                        uk {
                            name
                            description
                            metaTitle
                            metaDescription
                            metaKeyword
                        }
                    }
                }
                categoryMeta {
                    uk {
                        name
                        description
                        metaTitle
                        metaDescription
                        metaKeyword
                    }
                }
                optionsOpencart {
                    sortOrder
                }
                icon
                colors
                urlPrefix
                createdAt
                updatedAt
                status
            }
            total
        }
    }
`;

export const GET_CATEGORIES_TABLE = gql`
    query CategoriesTable($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        CategoriesTable(offset: $offset, limit: $limit, filters: $filters, sort: $sort, populate: $populate) {
            docs {
                _id
                company {
                    _id
                    name
                }
                categoryId
                parent {
                    _id
                    categoryId
                    categoryMeta {
                        uk {
                            name
                            description
                            metaTitle
                            metaDescription
                            metaKeyword
                        }
                    }
                }
                categoryMeta {
                    uk {
                        name
                        description
                        metaTitle
                        metaDescription
                        metaKeyword
                    }
                }
                optionsOpencart {
                    sortOrder
                }
                icon
                colors
                urlPrefix
                createdAt
                updatedAt
                status
            }
            total
        }
    }
`;

export const GET_PRODUCTS = gql`
    query Products($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        Products(offset: $offset, limit: $limit, filters: $filters, sort: $sort, populate: $populate) {
            docs {
                _id
                company {
                    _id
                    name
                }
                productId
                productIdAPI
                category {
                    _id
                    categoryId
                    categoryMeta {
                        uk {
                            name
                            description
                            metaTitle
                            metaDescription
                            metaKeyword
                        }
                    }
                }
                categories
                provider {
                    name
                }
                productMeta {
                    uk {
                        name
                        description
                        metaTitle
                    }
                }
                start
                price
                min
                max
                sortOrder
                status
                createdAt
                updatedAt
            }
            total
        }
    }
`;

export const GET_PRODUCTS_TABLE = gql`
    query ProductsTable($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        ProductsTable(offset: $offset, limit: $limit, filters: $filters, sort: $sort, populate: $populate) {
            docs {
                _id
                company {
                    _id
                    name
                }
                productId
                productIdAPI
                category {
                    _id
                    categoryId
                    categoryMeta {
                        uk {
                            name
                            description
                            metaTitle
                            metaDescription
                            metaKeyword
                        }
                    }
                }
                categories
                provider {
                    _id
                    name
                }
                productMeta {
                    uk {
                        name
                        description
                        metaTitle
                    }
                }
                start
                price
                min
                max
                sortOrder
                status
                createdAt
                updatedAt
            }
            total
        }
    }
`;

export const GET_PROVIDERS_TABLE = gql`
    query ProvidersTable($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        ProvidersTable(offset: $offset, limit: $limit, filters: $filters, sort: $sort, populate: $populate) {
            docs {
                _id
                company {
                    _id
                    name
                }
                providerId
                name
                token
                urlCheck
                urlCreate
                balance
                currency {
                    name
                    badge
                }
                status
                delete
                createdAt
                updatedAt
            }
            total
        }
    }
`;

export const GET_PROVIDERS = gql`
    query Providers($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        Providers(offset: $offset, limit: $limit, filters: $filters, sort: $sort, populate: $populate) {
            docs {
                _id
                company {
                    _id
                    name
                }
                providerId
                name
                token
                urlCheck
                urlCreate
                balance
                currency {
                    name
                    badge
                }
                status
                delete
                createdAt
                updatedAt
            }
            total
        }
    }
`;

export const GET_COMPANIES = gql`
    query Companies($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        Companies(offset: $offset, limit: $limit, filters: $filters, sort: $sort, populate: $populate) {
            docs {
                _id
                name
                url
            }
            total
        }
    }
`;

export const GET_COUNT_WARN_ORDERS = gql`
    query Orders($offset: Float, $limit: Float, $filters: [FilterInput!] = [
        {operator: "eq", column: "products.apiOrderId", find: {string: null}},
        {operator: "ne", column: "products.provider", find: {string: null}},
        {operator: "eq", column: "status", find: {string: "PAID"}}
    ], $sort: [SortInput!], $populate: [String!]) {
        OrdersWarn(
            offset: $offset
            limit: $limit
            filters: $filters
            sort: $sort
            populate: $populate
        ) {
            total
        }
    }
`;

export const GET_COUNT_REFUNDED_ORDERS = gql`
    query Orders($offset: Float, $limit: Float, $filters: [FilterInput!] = [
        {operator: "eq", column: "status", find: {string: "REFUNDED"}}
    ], $sort: [SortInput!], $populate: [String!]) {
        OrdersWarn(
            offset: $offset
            limit: $limit
            filters: $filters
            sort: $sort
            populate: $populate
        ) {
            total
        }
    }
`;

export const GET_ORDERS_WARN = gql`
    query OrdersWarn($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        OrdersWarn(
            offset: $offset
            limit: $limit
            filters: $filters
            sort: $sort
            populate: $populate
        ) {
            docs {
                _id
                orderId
                cookies
                repeatOrder
                total
                chargeUsd
                products {
                    _id
                    price
                    url
                    productIdAPI
                    pcs
                    total
                    chargeUsd
                    apiOrderId
                    status
                    product {
                        _id
                        productId
                        productIdAPI
                        productMeta {
                            uk {
                                name
                            }
                        }
                        categories
                    }
                    provider {
                        _id
                        providerId
                        name
                    }
                    analogProducts {
                        _id
                        name
                    }
                }
                company {
                    _id
                    name
                }
                status
                charge
                chargeUsd
                createdAt
                updatedAt
                invoice
            }
            total
        }
    }
`;

export const GET_USERS = gql`
    query Users($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        Users(
            offset: $offset
            limit: $limit
            filters: $filters
            sort: $sort
            populate: $populate
        ) {
            docs {
                _id
                first_name
                phone
                email
                telegram
                role
            }

        }
    }
`;

export const USER = gql`
    query User {
        User {
            _id
            first_name
            phone
            email
            telegram
            role
        }
    }
`;

export const COMPANIES_SELECT = gql
    `query CompanySelect {
        CompanySelect{
            value
            name
        }
    }`;

export const CATEGORIES_SELECT = gql
    `query CategoriesSelect($companyId: String, $companyIds: [String!]) {
        CategoriesSelect(companyId: $companyId, companyIds: $companyIds){
            value
            name
            company
        }
    }`;

export const PROVIDERS_SELECT = gql
    `query ProviderSelect($companyId: String) {
        ProviderSelect(companyId: $companyId){
            value
            name
        }
    }`;

export const PRODUCTS_MIX_SELECT = gql
    `query ProductsMixSelect($companyId: String) {
        ProductsMixSelect(companyId: $companyId){
            value
            name
        }
    }`;

export const CURRENCY_SELECT = gql
        `query CurrencySelect {
        CurrencySelect{
            value
            name
        }
    }`;

export const PAYMENT_TYPES_SELECT = gql
        `query PaymentTypesSelect {
        PaymentTypesSelect{
            value
            name
        }
    }`;

export const GET_CATEGORY = gql`
    query Category($id: String!, $populate: [String!]) {
        Category(id: $id, populate: $populate) {
            _id
            company {
                _id
                name
            }
            categoryId
            parent {
                _id
                categoryId
                categoryMeta {
                    uk {
                        name
                        description
                        metaTitle
                        metaDescription
                        metaKeyword
                    }
                }
            }
            categoryMeta {
                uk {
                    name
                    description
                    metaTitle
                    metaDescription
                    metaKeyword
                }
            }
            optionsOpencart {
                sortOrder
            }
            icon
            colors
            urlPrefix
            createdAt
            updatedAt
            status
        }
    }
`;

export const GET_PRODUCT = gql`
    query Product($id: String!, $populate: [String!]) {
        Product(id: $id, populate: $populate) {
            _id
            company {
                _id
                name
            }
            productId
            productIdAPI
            category {
                _id
                categoryId
                categoryMeta {
                    uk {
                        name
                        description
                        metaTitle
                        metaDescription
                        metaKeyword
                    }
                }
            }
            provider {
                _id
                name
            }
            mixProduct
            productMeta {
                uk {
                    name
                    description
                    metaTitle
                    metaDescription
                    metaKeyword
                }
            }
            start
            price
            min
            max
            sortOrder
            status
            createdAt
            updatedAt
        }
    }
`;

export const GET_PROVIDER = gql`
    query Provider($id: String!, $populate: [String!]) {
        Provider(id: $id, populate: $populate) {
            _id
            company {
                _id
                name
            }
            providerId
            name
            token
            urlCheck
            urlCreate
            balance
            currency {
                name
                badge
            }
            status
            delete
            createdAt
            updatedAt
        }
    }
`;

export const GET_PAYMENTS = gql`
    query Payments($offset: Float, $limit: Float, $filters: [FilterInput!], $sort: [SortInput!], $populate: [String!]) {
        Payments(offset: $offset, limit: $limit, filters: $filters, sort: $sort, populate: $populate) {
            docs {
                _id
                company {
                    _id
                    name
                }
                userId {
                    _id
                    first_name
                }
                type {
                    _id
                    name
                }
                currency {
                    _id
                    name
                    code
                    badge
                }
                value
                valueUsd
                description
                currencyValue
                income
                outcome
                createdAt
                updatedAt
            }
            total
        }
    }
`;

export const GET_PAYMENT = gql`
    query Payment($id: String!, $populate: [String!]) {
        Payment(id: $id, populate: $populate) {
                _id
                company {
                    _id
                    name
                }
                userId {
                    _id
                    first_name
                }
                type {
                    _id
                    name
                }
                currency {
                    _id
                    name
                    code
                    badge
                }
                value
                valueUsd
                description
                currencyValue
                income
                outcome
                createdAt
                updatedAt
        }
    }
`;

export const GET_STATUSES = gql `
    query Statuses{
        Statuses{
            name
            value
        }
    }
`;

export const GET_DASHBOARD = gql `
    query Dashboard($date: String!, $company: String!) {
        Dashboard(date: $date, company: $company) {
            traffic
            clicks
            orders
            income
            outcome
            advertising
            cost
            adminExp
            profit
            cpo
            conversion
            taxes
            profitability
        }
    }
`;

export const GET_DASHBOARD_CHART = gql`
    query DashboardChart($period: String!, $company: String!) {
        DashboardChart(period: $period, company: $company) {
            date
            traffic
            clicks
            orders
            income
            outcome
            advertising
            cost
            adminExp
            profit
            cpo
            conversion
            taxes
            profitability
        }
    }
`;

export const GET_DASHBOARD_BALANCES = gql`
    query Providers ($filters: [FilterInput!] = [{column: "balance", find: {number: 0}, operator: "gte"}], $sort: [SortInput!] = [{column: "balance", operator: -1}], $populate: [String!] = ["currency"]){
        Providers(filters: $filters, sort: $sort, populate: $populate){
            docs{
                balance
                name
                currency{
                    badge
                }
            }
        }
    }
`;

export const GET_DASHBOARD_TOP_ORDERS = gql`
    query DashboardTopOrders($date: String!, $company: String!) {
        DashboardTopOrders(date: $date, company: $company) {
            column
            value
            name
        }
    }
`;
