export function paginationObject() {
  return {
    merge: (existing, incoming, { args: { offset = 0, limit = 50 } }) => {

      if (!existing || offset === 0) {
        return incoming;
      }

      //если запрашиваемый офсет больше или равен докам что уже есть то просто склеить два масива
      if (offset >= existing.docs.length) {
        //обычная пагинация
        return { ...existing, docs: [...existing.docs, ...incoming.docs] };
      } else {
        //если в кеш залетело после мутации новый док
        //создаем КОПИЮ масива который уже был в кеше c нуля до всего доков в таблице
        //так как масив не может быть больше чем всего доков в таблице
        //при фильтре доков может стать меньше чем было
        let docs = existing.docs.slice(0, incoming.total);

        //меняем этот масив с нулевого до лимита и ставим то что пришло а пришло уже новым доком
        //это нужно что бы кеш не обнулялся при создание заказа например
        docs.splice(offset, limit, ...incoming.docs);

        return {
          ...incoming,
          docs,
        };
      }
    },
    read: (existing) => existing,
  };
}
