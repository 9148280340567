import {FormattedMessage} from "react-intl";
import {IconPlus, IconApps, IconList, IconPackage, IconSettingsAutomation, IconBuilding} from "@tabler/icons";

export const setting = {
  id: 'setting',
  title: <FormattedMessage id="setting" />,
  type: 'group',
  children: [
    {
      id: 'categories',
      title: <FormattedMessage id="categories" />,
      icon: IconApps,
      type: 'collapse',
      children: [
        {
          id: 'categories',
          title: <FormattedMessage id="categories" />,
          type: 'item',
          url: '/categories/all',
          icon: IconList,
          breadcrumbs: false
        },
        {
          id: 'add_category',
          title: <FormattedMessage id="add_category" />,
          type: 'item',
          url: '/categories/create',
          icon: IconPlus,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'products',
      title: <FormattedMessage id="products" />,
      icon: IconPackage,
      type: 'collapse',
      children: [
        {
          id: 'products',
          title: <FormattedMessage id="products" />,
          type: 'item',
          url: '/products/all',
          icon: IconList,
          breadcrumbs: false
        },
        {
          id: 'add_product',
          title: <FormattedMessage id="add_product" />,
          type: 'item',
          url: '/products/create',
          icon: IconPlus,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'providers',
      title: <FormattedMessage id="providers" />,
      icon: IconSettingsAutomation,
      type: 'collapse',
      children: [
        {
          id: 'providers',
          title: <FormattedMessage id="providers" />,
          type: 'item',
          url: '/providers/all',
          icon: IconList,
          breadcrumbs: false
        },
        {
          id: 'add_provider',
          title: <FormattedMessage id="add_provider" />,
          type: 'item',
          url: '/providers/create',
          icon: IconPlus,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'companies',
      title: <FormattedMessage id="companies" />,
      icon: IconBuilding,
      type: 'item',
      url: '/companies/all',
    }
  ]
};