import {FormattedMessage} from "react-intl";
import {IconList, IconReceiptRefund, IconAlertTriangle} from "@tabler/icons";

export const orders = {
  id: 'orders',
  title: <FormattedMessage id="orders" />,
  type: 'group',
  children: [
    {
      id: 'orders_warning',
      title: <FormattedMessage id="orders_warning" />,
      type: 'item',
      url: '/orders/warning',
      icon: IconAlertTriangle,
      breadcrumbs: false,
      chip: {
        label: 0,
        color: 'secondary',
        type: ''
      }
    },
    {
      id: 'orders_refunded',
      title: <FormattedMessage id="orders_refunded" />,
      type: 'item',
      url: '/orders/refunded',
      icon: IconReceiptRefund,
      breadcrumbs: false,
      chip: {
        label: 0,
        color: 'secondary',
        type: ''
      }
    },
    {
      id: 'ordersAll',
      title: <FormattedMessage id="ordersAll" />,
      type: 'item',
      url: '/orders/all',
      icon: IconList,
      breadcrumbs: false
    },
    // {
    //   id: 'add_order',
    //   title: <FormattedMessage id="add_order" />,
    //   type: 'item',
    //   url: '/dashboard/analytics',
    //   icon: IconPlus,
    //   breadcrumbs: false
    // }
  ]
};