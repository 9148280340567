import { dashboard } from './dashboard';
import { widget } from './widget';
import { application } from './application';
import { forms } from './forms';
import { elements } from './elements';
import { pages } from './pages';
import { utilities } from './utilities';
import { support } from './support';
import {other} from "./other";
import {orders} from "./orders";
import {setting} from "./setting";
import {users} from "./users";
import {payments} from "./payments";

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    // items: [dashboard, orders, setting, users, widget, application, forms, elements, pages, utilities, support, other]
    items: [dashboard, orders, payments, setting, users]
};

export default menuItems;
