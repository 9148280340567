import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, ApolloProvider, InMemoryCache, split } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { paginationObject } from "./helpers/paginations";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

// third party
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

// load mock apis
import './_mockApis';

// project imports
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config';
import reducer from './store/reducer';

// style + assets
import './assets/scss/style.scss';

//-----------------------|| REACT DOM RENDER  ||-----------------------//

const store = createStore(reducer);

//for SUBSCRIPTION
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API,
  credentials: "include",
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS,
  options: {
    reconnect: false,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      // User: {
      //   fields: {
      //     cart: {
      //       read: (existing) => existing,
      //       merge: (_, incoming) => incoming,
      //     },
      //     productsColumn: {
      //       read: (existing) => existing,
      //       merge: (_, incoming) => incoming,
      //     },
      //   },
      // },
      Query: {
        fields: {
          Users: paginationObject(),
          Orders: paginationObject(),
          Categories: paginationObject(),
          Products: paginationObject(),
          Providers: paginationObject(),
        },
      },
    },
  }),
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
