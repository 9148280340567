// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
export const SET_MENU = 'SET_MENU';

// action - customization reducer
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

export const TABLE_EDIT_ADD_ITEM = '@snackbar/TABLE_EDIT_ADD_ITEM';
export const TABLE_EDIT_EDIT_ITEM = '@snackbar/TABLE_EDIT_EDIT_ITEM';
export const TABLE_EDIT_DELETE_PROPERTY = '@snackbar/TABLE_EDIT_DELETE_PROPERTY';
export const TABLE_EDIT_DELETE_ALL_ITEMS = '@snackbar/TABLE_EDIT_DELETE_ALL_ITEMS';

export const addItemTableEdit = (item) => {
  return {
    type: TABLE_EDIT_ADD_ITEM,
    payload: item
  }
}

export const editItemTableEdit = (itemId, property, value) => {
  return {
    type: TABLE_EDIT_EDIT_ITEM,
    payload: { itemId, property, value }
  }
}

export const deletePropertyTableEdit = (itemId, propertyName) => {
  return {
    type: TABLE_EDIT_DELETE_PROPERTY,
    payload: {
      itemId,
      propertyName
    }
  }
}

export const deleteAllItemsTableEdit = () => {
  return {
    type: TABLE_EDIT_DELETE_ALL_ITEMS
  }
}
