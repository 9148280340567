// action - state management
import * as actionTypes from './actions';

const initialState = {
  items: []
};

// Load the state from local storage, if available
const storedState = null // localStorage.getItem('tableEditState');
const persistedState = storedState ? JSON.parse(storedState) : {};

//-----------------------|| TABLE EDIT REDUCER ||-----------------------//

const tableEditReducer = (state = { ...initialState, ...persistedState }, action) => {
  let update = null;

  switch (action.type) {
    case actionTypes.TABLE_EDIT_ADD_ITEM:
      const { _id } = action?.payload;

      if(state?.items?.find((item) => item?._id === _id))
        state?.items?.find((item) => {
          if(item?._id === _id)
            Object.entries(item).forEach(([key, value]) => {
              if(key !== '_id')
                Object.entries(action.payload).forEach(([key2, value2]) => {
                  if(key2 !== '_id')
                    item[key2] = value2
                })
            });
        })
      else
        state.items = [...state.items, action.payload]

      update = {
        ...state,
        items: state.items
      };

      localStorage.setItem('tableEditState', JSON.stringify(update));
      return update;

    case actionTypes.TABLE_EDIT_EDIT_ITEM:
      const { itemId: editId, property: editProperty, value: editValue } = action?.payload;

      const items = state.items.map(item => {
        if (item.id === editId) {
          return {
            ...item,
            [editProperty]: editValue
          }
        } else {
          return item
        }
      });

      update = {
        ...state,
        items
      };

      localStorage.setItem('tableEditState', JSON.stringify(update));
      return update;

    case actionTypes.TABLE_EDIT_DELETE_PROPERTY:
      const { itemId: deleteId, property: deleteProperty } = action?.payload;

      const updatedItems = state.items.map(item => {
        if (item.id === deleteId) {
          const { [deleteProperty]: deletedProperty, ...itemWithoutDeletedProperty } = item;
          return itemWithoutDeletedProperty;
        } else {
          return item;
        }
      });

      update = {
        ...state,
        items: updatedItems
      };

      localStorage.setItem('tableEditState', JSON.stringify(update));
      return update;

    case actionTypes.TABLE_EDIT_DELETE_ALL_ITEMS:
      update = {
        ...state,
        items: []
      };

      localStorage.setItem('tableEditState', JSON.stringify(update));
      return update;

    default:
      return state;
  }
};

export default tableEditReducer;
