import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import tableEditReducer from "./tableEditReducer";

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    tableEdit: tableEditReducer
});

export default reducer;
