import {FormattedMessage} from "react-intl";
import {IconList, IconReceiptRefund, IconAlertTriangle, IconSettingsAutomation, IconPlus} from "@tabler/icons";

export const payments = {
  id: 'payments',
  title: <FormattedMessage id="payments" />,
  type: 'group',
  children: [
    {
      id: 'add_payment',
      title: <FormattedMessage id="add_payment" />,
      type: 'item',
      url: '/payments/create',
      icon: IconPlus,
      breadcrumbs: false
    },
    {
      id: 'paymentsAll',
      title: <FormattedMessage id="paymentsAll" />,
      type: 'item',
      url: '/payments/all',
      icon: IconList,
      breadcrumbs: false
    }
  ]
};